<template>
  <v-slide-y-transition appear>
    <v-card class="pa-3 pa-md-5 mx-auto invite" light>
      <v-card-title
        class="text-headline text-left"
        v-text="'Add Content Collaborator'"
      />
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            v-model="form.email"
            :rules="emailRules"
            color="primary"
            label="Email"
            type="email"
            outlined
            dense
            required
          />
          <v-select
            v-model="form.role_code"
            :items="roles"
            :rules="roleRules"
            persistent-hint
            single-line
            return-object
            label="Select Role"
            item-text="role_name"
            item-value="role_code"
            color="primary"
            outlined
            dense
            required
            :hint="`${form.role_desc}`"
            @change="setRole"
          >
            <template #item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.role_name }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.desc }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-select>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          rounded
          text
          color="dark"
          class="ma-1"
          @click.native="$emit('closeInvite')"
        >
          Cancel
        </v-btn>
        <v-btn
          rounded
          color="primary"
          class="ma-1"
          :loading="loading"
          @click.native="submit"
        >
          Invite
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-slide-y-transition>
</template>

<script>
import { mapState } from 'vuex';
import { api } from '@/plugins/axios';

export default {
  name: 'UserAdd',
  props: {
    roleCode: {
      type: String,
    },
    roles: {
      type: Array,
    },
  },
  data() {
    return {
      form: {
        email: null,
        org_id: null,
        role_code: null,
        sub_id: null,
        sub_name: null,
        permission: null,
        role_desc: '',
      },
      valid: true,
      orgRules: [(v) => !!v || 'Organization is required'],
      roleRules: [(v) => !!v || 'Role is required'],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
      loading: false,
    };
  },
  computed: {
    ...mapState({
      orgId: (state) => state.user.orgId,
      orgName: (state) => state.user.orgName,
    }),
  },
  methods: {
    setRole(item) {
      this.form.role_code = item.role_code;
      this.form.role_name = item.role_name;
      this.form.role_desc = item.desc;
      return item.value;
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        try {
          let obj = {
            email: this.form.email,
            role_code: this.form.role_code,
            role_name: this.form.role_name,
            org_id: this.orgId,
            org_name: this.orgName,
            sub_id: null,
            sub_name: null,
            permission: 'full',
          };
          await api.post('/users/invitations', obj);
          this.$store.dispatch('notification/add', {
            message: 'Invite message has been sent',
            type: 'success',
          });
          this.$emit('closeInvite');
          this.loading = false;
          this.form = {
            email: null,
            org_id: null,
            org_name: null,
            role_code: null,
            role_name: null,
            sub_id: null,
            sub_name: null,
            permission: null,
          };
        } catch (err) {
          this.loading = false;
          this.$emit('closeInvite');
          this.$store.dispatch('notification/add', {
            message: 'Email address has been used',
            type: 'error',
          });
        }
      }
    },
  },
};
</script>
